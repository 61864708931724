import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/home",
      name: "home",
      component: () => import("./components/Home"),
      props: true,
    },
    {
      path: "/viewpdf/:id",
      name: "certificado",
      component: () => import("./components/Certificado"),
    },
    {
      path: "/admin/rehab",
      name: "rehabilitador",
      component: () => import("./components/admin/Rehab"),
    },
    {
      path: "/admin/stats",
      name: "stats",
      component: () => import("./components/admin/Stats"),
    },
  ],
});
